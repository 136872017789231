import React, { useState, useEffect } from "react";
import NumberFormat from 'react-number-format'; 
import { Link } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { useTranslation } from 'react-i18next';
import classnames from "classnames";
import clsx from 'clsx';

import { thousandAmount } from "../../../../helpers";
import ConfirmDeposit from "../modals/Confirm";
import Wincoin from "../modals/Wincoin";
import DepositPromotions from "../modals/DepositPromotions";
import {
    getDepositPromotions,
    checkDepositPromotion
} from "../../../../actions/promotion";
import { data } from "jquery";

const LocalBankForm = ({ props, state, onChange, onClick, quickAddAmount, resetForm, showConfirm, closeConfirm, onResend, onSubmit, checkDepositAccount, depositBlacklistError, depositCloseError, onClickPaymentHandler, closeWincoin, setPaymentOption }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let { errors } = props;
    let { errors: { amount } } = state;
    let { currency: { currency } } = props;
    const language = i18n.language;
    const [showDepPromo, toggleShowDepPromo] = useState(false);
    const [depositOptionCategory, setDepositOptionCategory] = useState(0);

    const showDepositPromotions = () => {
        toggleShowDepPromo(true);
        dispatch(getDepositPromotions());
        dispatch(checkDepositPromotion());
    }

    const closeDepositPromotions = () => {
        toggleShowDepPromo(false);
    }

    useEffect(() => {
        if(amount){
            closeConfirm();
        }
    }, [amount])

    useEffect(() => {
        let temp = state.depositOptionIDs;
        
        if (temp.length > 0) {
            if (!temp.includes(5) && temp.includes(1) && !temp.includes(3)) {
                setDepositOptionCategory(1);
            } else if (!temp.includes(5) && temp.includes(1) && temp.includes(3)) {
                setDepositOptionCategory(2);
            } else if (temp.includes(5) && !temp.includes(1) && !temp.includes(3)) {
                setDepositOptionCategory(3);
            } else if (temp.includes(5) && !temp.includes(1) && temp.includes(3)) {
                setDepositOptionCategory(4);
                setPaymentOption(5);
            } else if (temp.includes(5) && temp.includes(1) && !temp.includes(3)) {
                setDepositOptionCategory(5);
            } else {
                setDepositOptionCategory(1);
            }
        } else {
            setDepositOptionCategory(1);
        }
    }, [state.depositOptionIDs])

    const SelectOptions = () => {
        return (
            <>
                <div class="row">
                    <div class="col-md-12">
                        <label class="col-form-label">{t('select option')}:</label>
                    </div>
                </div>
                <div className="form-group row mx-0">
                    <button class={clsx("btn select-option", {
                            'btn-success': state.paymentOption !== 1,
                            'btn-secondary': state.paymentOption === 1,
                        })}
                        onClick={() => onClickPaymentHandler(1)}
                    >{t('local bank')}</button>
                    <button class={clsx("btn select-option", {
                            'btn-success': state.paymentOption !== 5,
                            'btn-secondary': state.paymentOption === 5,
                        })}
                        onClick={() => onClickPaymentHandler(5)}
                    >{t('coin')}</button>
                    {/* <button class={clsx("btn select-option", {
                            'btn-success': state.paymentOption !== 1,
                            'btn-secondary': state.paymentOption === 1,
                        })}
                        onClick={() => onClickPaymentHandler(1)}
                    >{t('local bank')}</button>
                    <button class={clsx("btn select-option", {
                        'btn-success': state.paymentOption !== 5,
                        'btn-secondary': state.paymentOption === 5,
                        })}
                        onClick={() => onClickPaymentHandler(5)}
                    >{t('coin')}</button> */}

                    {/* <div class=" pr-1" onClick={() => onClickPaymentHandler(1)}>
                        <button class={clsx("btn select-option", {
                            'btn-secondary': state.paymentOption !== 1,
                            'btn-success': state.paymentOption === 1,
                        })}
                        onClick={() => onClickPaymentHandler(1)}
                        >{t('local bank')}</button>
                    </div>
                    <div class=" pl-1" onClick={() => onClickPaymentHandler(5)}>
                    </div> */}
                </div>
            </>
        )
    }

    return (
        <div className="accountInfo mb-5">
            {
                state.bankAccountName ?
                    <React.Fragment>
                        <DepositPromotions show={showDepPromo} handleClose={closeDepositPromotions} amount={state.amount} minAmount={state.minAmount}/>
                        {/* {
                            state.depositOptionIDs.length > 1 ? 
                            state.depositOptionIDs.includes(5) ?
                            <SelectOptions/> : <></> : <></>
                        } */}
                        
                        {depositOptionCategory === 5 ? <SelectOptions/> : <></>}
                        <div className="form-group row">
                            <div class="col-md-12 ">
                                <label class="col-form-label">{t('amount')}</label>
                            </div>
                            <div class="col-md-12 txtbox-min-max">
                                <div class="form-group mb-0">
                                    <NumberFormat
                                        className="form-control"
                                        displayType={"input"}
                                        thousandSeparator={true}
                                        decimalSeparator={false}
                                        onChange={onChange}
                                        onClick={onClick}
                                        decimalScale={0}
                                        id="amount"
                                        name="amount"
                                        placeholder="0"
                                        value={state.amount}
                                        required
                                        disabled={state.minAmount === 0 || state.maxAmount === 0 ? true : false}
                                    />
                                    <div class="min-max-amount">
                                        {t('minimum')}/{t('maximum')} {thousandAmount(state.minAmount)}/{thousandAmount(state.maxAmount)}
                                    </div>
                                </div>
                                <div className={classnames("invalid-tooltip", { "d-block": (amount || errors.transaction) || (state.maxError || state.minError) })} >
                                    <div className={classnames({ "d-none": !state.validated })}>
                                        {state.amount === "" ? t('this field is required') : ""}
                                        {errors && errors.transaction ? t('deposit validation') : ''}
                                    </div>
                                    <div className={classnames({
                                        "d-none": !state.maxError
                                    })}>
                                        {state.maxError ? t('the amount must not more than') + ' ' + thousandAmount(state.maxAmount) + '.' : ''}
                                    </div>
                                    <div className={classnames({
                                        "d-none": !amount
                                    })}>
                                        {amount}
                                    </div>
                                    <div className={classnames({
                                        "d-none": !state.minError && state.amount !== ''
                                    })}>
                                        {(state.minError || state.amount !== '') ? t("the amount must be at least") + ' ' + thousandAmount(state.minAmount) + '.' : ''}
                                    </div>
                                </div>
                                <div class="row m-0">
                                    <button type="button" disabled={state.minAmount === 0 || state.maxAmount === 0 ? true : false} onClick={quickAddAmount.bind(this, 10000)} class="btn btn-secondary col">{language === 'en-US' ? '+10K' : '+1만'}</button>
                                    <button type="button" disabled={state.minAmount === 0 || state.maxAmount === 0 ? true : false} onClick={quickAddAmount.bind(this, 50000)} class="btn btn-secondary col">{language === 'en-US' ? '+50K' : '+5만'}</button>
                                    <button type="button" disabled={state.minAmount === 0 || state.maxAmount === 0 ? true : false} onClick={quickAddAmount.bind(this, 100000)} class="btn btn-secondary col">{language === 'en-US' ? '+100K' : '+10만'}</button>
                                    <button type="button" disabled={state.minAmount === 0 || state.maxAmount === 0 ? true : false} onClick={quickAddAmount.bind(this, 500000)} class="btn btn-secondary col">{language === 'en-US' ? '+500K' : '+50만'}</button>
                                    <button type="button" disabled={state.minAmount === 0 || state.maxAmount === 0 ? true : false} onClick={quickAddAmount.bind(this, 1000000)} class="btn btn-secondary col">{language === 'en-US' ? '+1M' : '+100만'}</button>
                                    <button type="button" disabled={state.minAmount === 0 || state.maxAmount === 0 ? true : false} onClick={resetForm} class="btn btn-success btn-sm col">{t('reset')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 ">
                                <label className="col-form-label">{t('bank account name')}</label>
                            </div>
                            <div className="col-md-12 ">
                                <input type="text" className="form-control accountNameDisabled" value={state.bankAccountName} aria-label="Account Name" disabled={true} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 ">
                                <label className="col-form-label">{t('promotion')}</label>
                            </div>
                            {
                                props.player.Status  === 5 || props.player.Status  === 2 || props.player.Status  === 4 ?
                                <div className="col-md-12 ">
                                    <div 
                                        className="form-control accountNameDisabled depositPromotionSelectDisabled" 
                                        style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}} 
                                    >
                                       <img src="/assets/img/w365/icons/promotion-popup-disabled.svg" style={{width: '17px', marginLeft: 'auto'}}/>
                                    </div>
                                </div>
                                :
                                <div className="col-md-12 ">
                                    <div 
                                        className="form-control accountNameDisabled depositPromotionSelect" 
                                        style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}} 
                                        onClick={() => showDepositPromotions()}
                                    >
                                        { state.selectedPromotion === null ? t("will get rebate") : state.selectedPromotion.Code } <img src="/assets/img/w365/icons/deposit-promotion-modal-icon.svg" style={{width: '17px', marginLeft: 'auto'}}/>
                                    </div>
                                    {
                                        props.hasPendingSubcription ? 
                                        <React.Fragment>
                                            <img src="/assets/img/w365/icons/info-rebate-icon.svg" style={{ width: '15px' }}/> 
                                            <span style={{
                                                fontSize: '12px',
                                                fontStyle: 'italic',
                                                marginLeft: '5px'
                                            }}>
                                                {t('rebate will be given after bonus and coupon wagering is done')}
                                            </span>
                                        </React.Fragment>
                                        :
                                        <></>
                                    }
                                </div>
                            }
                        </div>
                        {/* {
                            state.paymentOption === 1 ?
                            state.depositOptionIDs.includes(3) ?
                                props.depositAccount.length !== 0 ?
                                    <div className="bank-details">
                                        <p className="secondary-color font-bold" style={{ fontSize: '16px' }}>{t('deposit account')}</p>
                                        <div className="form-group row align-items-center">
                                            <div className="col-4 labelColor pr-o">{t('bank name')}:</div>
                                            <div className="col-auto pl-0">
                                                <p className="mb-0 font-bold">{props.depositAccount.BankName}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center mb-0">
                                            <div className="col-4 labelColor pr-o">{t('deposit account details')}:</div>
                                            <div className="col-auto pl-0">
                                                <p className="mb-0 font-bold">{props.depositAccount.AccountNo} / {props.depositAccount.AccountName}</p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="bank-details">
                                        {t('no deposit bank details')}
                                    </div>
                                : ( (state.depositOptionIDs.includes(1) && state.depositOptionIDs.length === 1 ) || state.depositOptionIDs.length === 0) && state.localBank.AccountNo !== undefined ? 
                                    <div className="bank-details">
                                        <p className="secondary-color font-bold" style={{ fontSize: '16px' }}>{t('deposit account')}</p>
                                        <div className="form-group row align-items-center">
                                            <div className="col-4 labelColor pr-o">{t('bank name')}:</div>
                                            <div className="col-auto pl-0">
                                                <p className="mb-0 font-bold">{state.localBank.BankName}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center mb-0">
                                            <div className="col-4 labelColor pr-o">{t('deposit account details')}:</div>
                                            <div className="col-auto pl-0">
                                                <p className="mb-0 font-bold">{state.localBank.AccountNo} / {state.localBank.AccountName}</p>
                                            </div>
                                        </div>
                                    </div>
                                    :  <></>
                                : <></>
                        } */}

                        {
                            depositOptionCategory === 1 && state.localBank.AccountNo !== undefined ?
                                <div className="bank-details">
                                    <p className="secondary-color font-bold" style={{ fontSize: '16px' }}>{t('deposit account')}</p>
                                    <div className="form-group row align-items-center">
                                        <div className="col-4 labelColor pr-o">{t('bank name')}:</div>
                                        <div className="col-auto pl-0">
                                            <p className="mb-0 font-bold">{state.localBank.BankName}</p>
                                        </div>
                                    </div>
                                    <div className="form-group row align-items-center mb-0">
                                        <div className="col-4 labelColor pr-o">{t('deposit account details')}:</div>
                                        <div className="col-auto pl-0">
                                            <p className="mb-0 font-bold">{state.localBank.AccountNo} / {state.localBank.AccountName}</p>
                                        </div>
                                    </div>
                                </div>
                            :
                            depositOptionCategory === 2 || depositOptionCategory === 4 ?
                                props.depositAccount.length !== 0 ?
                                    <div className="bank-details">
                                        <p className="secondary-color font-bold" style={{ fontSize: '16px' }}>{t('deposit account')}</p>
                                        <div className="form-group row align-items-center">
                                            <div className="col-4 labelColor pr-o">{t('bank name')}:</div>
                                            <div className="col-auto pl-0">
                                                <p className="mb-0 font-bold">{props.depositAccount.BankName}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center mb-0">
                                            <div className="col-4 labelColor pr-o">{t('deposit account details')}:</div>
                                            <div className="col-auto pl-0">
                                                <p className="mb-0 font-bold">{props.depositAccount.AccountNo} / {props.depositAccount.AccountName}</p>
                                            </div>
                                        </div>
                                    </div>
                                :
                                <div className="bank-details">
                                    {t('no deposit bank details')}
                                </div>
                            :
                            depositOptionCategory === 5 ?
                                state.paymentOption === 1 && state.localBank.AccountNo !== undefined ?
                                    <div className="bank-details">
                                        <p className="secondary-color font-bold" style={{ fontSize: '16px' }}>{t('deposit account')}</p>
                                        <div className="form-group row align-items-center">
                                            <div className="col-4 labelColor pr-o">{t('bank name')}:</div>
                                            <div className="col-auto pl-0">
                                                <p className="mb-0 font-bold">{state.localBank.BankName}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row align-items-center mb-0">
                                            <div className="col-4 labelColor pr-o">{t('deposit account details')}:</div>
                                            <div className="col-auto pl-0">
                                                <p className="mb-0 font-bold">{state.localBank.AccountNo} / {state.localBank.AccountName}</p>
                                            </div>
                                        </div>
                                    </div>
                                :
                                <></>   
                            :
                            <></>
                        }

                        <div className="action-btn-modal text-center">
                            <button className="btn btn-secondary small-caps" type="button" onClick={showConfirm} disabled={state.isSubscribed || state.submitDisabled || checkDepositAccount() || state.minAmount === 0 || state.maxAmount === 0 ? true : false}>{t('submit')}</button>
                        </div>

                        <ConfirmDeposit
                            show={state.showConfirm}
                            handleClose={closeConfirm}
                            bankAccountName={state.bankAccountName}
                            amount={state.amount}
                            onSubmit={onSubmit}
                            success={state.success}
                            errors={errors}
                            propsSuccess={state.transactionID}
                            phone={state.phone}
                            submitDisabled={state.submitDisabled}
                            resendSMS={onResend}
                            isBlacklisted={state.depositBlacklistError}
                            isClosed={state.depositCloseError}
                            isError400={state.error_400}
                            ongoingSubscription={state.ongoing_subcription}
                            paymentOption={state.paymentOption}
                            assignBankInfo={props.depositAccount.AccountNo}
                        />
                        <Wincoin
                            show={state.showWincoin}
                            handleClose={closeWincoin}
                        />
                    </React.Fragment>
                    :
                    <div className="text-center row">
                        <div className="col-md-12">
                            <div
                                className="alert alert-danger"
                                role="alert"
                            >
                                {t("no bank account yet")}
                            </div>

                            <Link to={`/myaccount/profile`}
                                className='btn btn-deposit-success btn-loader' >
                                {t('add bank details')}
                            </Link>
                        </div>
                    </div>
            }
        </div>
    )
}

export default LocalBankForm;