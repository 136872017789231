import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";

import { Loader } from "../../../common";
import transferIcon from '../../../../assets/img/w365/transfer-icon.svg';
import { displayAmount } from '../../../../helpers';
import RebateTransferModal from '../modals/RebateTransfer';
import { getRebateTransferData } from '../../../../actions/rebate';

const PlayerDropdown = ({badge, username, walletBalance, rebateBalance, refreshWallet, onLogout}) => {
    const useToggleState = () => {
        const [isOpen, setOpen] = useState(false);
        return { isOpen, toggle: () => setOpen((isOpen) => !isOpen) };
    };
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isOpen, toggle } = useToggleState();
    const { loadingWallet } = useSelector(state => state.auth);
    const rebateTransferData = useSelector(state => state.rebate.transferData);

    const auth = useSelector(state => state.auth);
    const { unreadMessage, unreadNotice } = auth;

    const unReadMessageCount = unreadMessage && unreadMessage.unread_message_replies_count !== undefined ? unreadMessage.unread_message_replies_count : 0
    const unReadNoticeCount = unreadNotice && unreadNotice.unread_notice_count !== undefined ? unreadNotice.unread_notice_count : 0

    return (
        <>
            <Dropdown isOpen={isOpen} toggle={toggle}>
                <DropdownToggle id="dropdownMenuLink" caret>
                    {username}
                </DropdownToggle>
                <DropdownMenu right={true}>
                    <div className="vip-badge-username mb-2">
                        <div><img src={badge}/> <span className="account-name--green">{username}</span></div>
                    </div>
                    <div className="balance">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="col-auto">
                                <p>{t("total balance")}:</p>
                                {
                                    loadingWallet ? 
                                    <Loader hide={!loadingWallet} style={{ width: "15px" }}/>
                                    :
                                    <b><p className="highlighted-text">KRW {walletBalance}</p></b>
                                }
                                <hr/>
                                <p>{t("rebate wallet balance")}:</p>
                                {
                                    loadingWallet ? 
                                    <Loader hide={!loadingWallet} style={{ width: "15px" }}/>
                                    :
                                    <b><p className="highlighted-text">KRW {rebateBalance}</p></b>
                                }
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-success" id="rebate-transfer-btn" onClick={() => dispatch(getRebateTransferData())} disabled={rebateTransferData.checkingLoading}>
                                    {rebateTransferData.checkingLoading ? <Loader style={{ width: "18px" }}/> : <img src={transferIcon} alt="" className="rebate-transfer-arrow" />}
                                    &nbsp;&nbsp;{t('transfer')}
                                </button>
                                <button onClick={refreshWallet} type="button" style={{padding: "10px"}} id="refresh-wallet-btn">
                                    <img className="ImgFluid" src="/assets/img/w365/icons/inactive/refresh_icon.svg" style={{cursor: 'pointer'}}/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <DropdownItem>
                            <Link to="/myaccount/deposit">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <p className="deposit-dropdown d-flex align-items-center">{t("deposit")}</p>
                                    </div>
                                    <div className="col-auto arrow-after">
                                        
                                    </div>
                                </div>
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/myaccount/withdrawal">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <p className="withdrawal-dropdown d-flex align-items-center">{t("withdrawal")}</p>
                                    </div>
                                    <div className="col-auto arrow-after">
                                        
                                    </div>
                                </div>
                            </Link>
                        </DropdownItem>
                        {/* <DropdownItem>
                            <Link to="/myaccount/deposit">
                                
                            </Link>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="col-auto">
                                    <p className="transfer-dropdown d-flex align-items-center">Transfer</p>
                                </div>
                                <div className="col-auto arrow-after">
                                    
                                </div>
                            </div>
                        </DropdownItem> */}
                        <DropdownItem>
                            <Link to="/myaccount/history">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <p className="history-dropdown d-flex align-items-center">{t("transaction history")}</p>
                                    </div>
                                    <div className="col-auto arrow-after">
                                        
                                    </div>
                                </div>
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/myaccount/bonus">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <p className="bonus-dropdown d-flex align-items-center">{t("bonus / coupon / cash")}</p>
                                    </div>
                                    <div className="col-auto arrow-after">
                                        
                                    </div>
                                </div>
                            </Link>
                        </DropdownItem>
                        {/* <DropdownItem>
                            <Link to="/myaccount/deposit">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <p className="rebates-dropdown d-flex align-items-center">{t("rebates")}</p>
                                    </div>
                                    <div className="col-auto arrow-after">
                                        
                                    </div>
                                </div>
                            </Link>
                        </DropdownItem> */}
                        <DropdownItem>
                            <Link to="/myaccount/profile">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <p className="profile-dropdown d-flex align-items-center">{t("profile")}</p>
                                    </div>
                                    <div className="col-auto arrow-after">
                                        
                                    </div>
                                </div>
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/myaccount/messages">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <p className="messages-dropdown d-flex align-items-center">{t("messages")}</p>
                                    </div>
                                    {  unReadMessageCount > 0 ? <div class="badge badge-danger">{unReadMessageCount}</div> : "" }
                                    <div className="col-auto arrow-after">
                                        
                                    </div>
                                </div>
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/myaccount/notices">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <p className="notice-dropdown d-flex align-items-center">{t("notices")}</p>
                                    </div>
                                    { unReadNoticeCount >= 10 ? <div className="badge badge-danger">{"9+"}</div> : unReadNoticeCount > 0 ? <div className="badge badge-danger">{unReadNoticeCount}</div> : "" }
                                    <div className="col-auto arrow-after">
                                        
                                    </div>
                                </div>
                            </Link>
                        </DropdownItem>
                        <DropdownItem className="logout-dropdown-item">
                            <Link to={`/`} onClick={onLogout}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <p className="logout-dropdown d-flex align-items-center">{t("logout")}</p>
                                    </div>
                                    <div className="col-auto arrow-after">
                                        
                                    </div>
                                </div>
                            </Link>
                        </DropdownItem>
                    </div>
                </DropdownMenu>
            </Dropdown>
            <RebateTransferModal mainWalletAmount={displayAmount(walletBalance)} rebateWalletAmount={displayAmount(rebateBalance)} />
        </>
    );
}

export default PlayerDropdown;
