import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";

import {
    postDeposit,
    postResend,
    resetDeposit,
    getProviders,
    selectProvider,
    getBankAccount,
    resetResendDeposit,
    resetResendError,
    getTronBalance,
    resetDepositError,
    getDepositOption
} from "../../../actions/payment";

import {
    checkBonus,
    resetBonus,
    resetOngoingPromotion
} from "../../../actions/bonus";

import {
    reloadWallet,
    getDepositAccount,
    clearDepositAccount
} from "../../../actions/player";

import {
    getPromotionMinDeposit,
    resetPromotionMinDeposit,
    getDepositPromotions,
    setDepositPromotionBonus,
    checkDepositPromotion,
    setDepositHotlink
} from "../../../actions/promotion";

import {
    selectProvider as selectModalProvider
} from "../../../actions/modal";
import { getProductProviders } from "../../../actions/product";
import { cancelSubscription } from "../../../actions/bonus";
import Provider from "../../layouts/Walletz/provider";
import { realAmount } from "../../../helpers";


import mainWallet from "../../../assets/img/logo/wallet/main-wallet.png";
import LocalBankForm from "./forms/localbank";

import { getChungDamMinMax } from '../../../api/payment';

//select options
export const LOCAL_BANK_DEPOSIT = 1;

//payment_types
export const LOCAL_BANK_DEPOSIT_TYPE = 1;
export const DEDICATED_BANK_ACCOUNT_PAYMENT_TYPE = 3;
export const CHUNG_DAM_PAYMENT_TYPE = 4;
export const WIN_COIN_PAYMENT_TYPE  = 5;
//const ONLINE_BANK = 1; //not used yet


const getSelectedOption = () => {
    return parseInt(localStorage.getItem('selectedOption'));
}

const setSelectOption = (value) => {
    localStorage.setItem('selectedOption', value)
}

class Deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            autoSelect: false,
            success: false,
            error: false,
            validated: false,
            disabled: false,
            loading: false,
            minError: false,
            maxError: false,
            tronMinError: false,
            tronMaxError: false,
            depositError: false,
            minAmount: 0,
            tempMinAmount: 0,
            maxAmount: 0,
            mainWallet: 0,
            rebate: 0,
            provider: 0,
            bankID: 0,
            wallet: {},
            errors: {},
            providers: [],
            bank: [],
            subscription: {
                promotion: {
                    Title: ""
                }
            },
            amount: null,
            tron_amount: null,
            bankAccountName: "",
            phone: "",
            init: false,
            isSubscribed: false,
            submitDisabled: false,
            paymentOption: LOCAL_BANK_DEPOSIT, //select option
            paymentOptionIDS: [],
            depositOptionIDs: [],
            localBank: {},
            showConfirm: false,
            depositBlacklistError: false,
            depositCloseError: false,
            paymentChungDamEnabled: false,
            transactionID: 0,
            transactionNumber: 0,
            isSuspended: false,
            bonus: null,
            selectedPromotion: null,
            error_500: false,
            error_400: false,
            ongoing_subcription: false,
            showWincoin: false,
        }
    }

    componentDidMount() {
        this.props.clearDepositAccount();
        const type = { transaction_type: 1000 };

        if (Object.keys(this.props.providers).length <= 0) {
            this.props.getProviders();
        }
        this.props.reloadWallet();
        this.props.resetDeposit();
        this.props.resetDepositError();
        this.props.getBankAccount(type);
        this.props.resetResendError();
        this.props.getDepositAccount();
        this.props.getTronBalance();
        this.props.getDepositOption({ domain: window.location.hostname === "localhost" ? "frontend-test.w365b.com" : window.location.hostname });
        this.props.getDepositPromotions();
        this.props.checkDepositPromotion();
        // getChungDamMinMax().then(response => {
        //     this.setState({
        //         minAmount: response.data.data.minDepositAmount,
        //         maxAmount: response.data.data.maxDepositAmount
        //     });
        // });
    }

    componentWillUnmount() {
        this.props.resetDeposit();
        this.props.resetResendDeposit();
        this.props.resetResendError();
        this.props.resetOngoingPromotion()
        this.props.resetDepositError();
        this.props.setDepositPromotionBonus(null, null);
    }

    componentWillReceiveProps(nextProps) {
        const { t } = this.props;
        // console.log("selected",nextProps.selectedPromotion);
        // console.log("hotlink",this.props.hotlinkPromotion);
        // if(nextProps.depositPromotions) {
        //     if(this.props.match.params.id) {
        //         if(nextProps.depositPromotions.length != 0) {
        //             if(this.props.hotlinkPromotion == null){
        //                 const d = new Date();
        //                 let day = d.getDay()
        //                 const filtered = nextProps.depositPromotions.filter((data) => {
        //                     return data.PromotionID === parseInt(this.props.match.params.id);
        //                 })
        //                 if(filtered.length != 0){
        //                     const days = filtered[0].RecurringDays.map((data) => {
        //                         return data.DayNumber;
        //                     })
        //                     if(!this.props.hasPendingSubcription && !this.props.loadingPendingSubscription) {
        //                         if(filtered[0].RecurringDays.length !== 0){
        //                             if(days.includes(day)){
        //                                 this.props.setDepositPromotionBonus(filtered[0].PromotionID, filtered[0]);
        //                             }
        //                         } else {
        //                             this.props.setDepositPromotionBonus(filtered[0].PromotionID, filtered[0]);
        //                         }
        //                     }
        //                     this.props.setDepositHotlink(filtered);
        //                 }
        //             }
        //         }
        //     }
        // }
        if (nextProps.depositOption) {
            this.setState({
                depositOptionIDs: nextProps.depositOption.IDS,
            });
            // console.log("length", nextProps.depositOption.IDS.length)
            if(nextProps.depositOption.IDS.length === 1) {
                if(nextProps.depositOption.IDS.includes(5)) {
                    this.setState({
                        paymentOption: 5,
                    });
                }
            }
        }

        if (nextProps.player.bank) {
            this.setState({
                bankAccountName: nextProps.player.bank.AccountName
            });
        }

        if (this.props.player.contact) {
            let phone = this.props.player.contact.filter((val) => { return val.TypeID === 2; });
            phone = phone[0].Info;
            phone = String(phone);
            phone = "+" + phone.substr(0, 3) + " **** " + phone.substr(phone.length - 4);
            this.setState({ phone: phone })
        }

        this.setState({
            wallet: nextProps.wallet,
        })

        if (nextProps.player) {
            this.setState({ isSuspended: nextProps.player.Status === 4 ? true : false })
        }

        this.setState({ bonus: nextProps.promotionID, selectedPromotion: nextProps.selectedPromotion });

        try {
            const { t } = this.props;

            let bank = nextProps.bankAccount;
            if (bank !== undefined) {
                if (bank.length > 0) {
                    let maxAmount = 0; let minAmount = realAmount(bank[0].MinDepositAmount)
                    bank.map(function (data, key) {
                        let maximum = realAmount(data.MaxDepositAmount)
                        let minimum = realAmount(data.MinDepositAmount)
                        if (maxAmount <= maximum) { maxAmount = maximum }
                        if (minAmount >= minimum) { minAmount = minimum }
                    })
                    if (this.state.paymentChungDamEnabled) {
                        this.setState({ bank: bank });
                    } else {
                        this.setState({ maxAmount: maxAmount, minAmount: minAmount, tempMinAmount: minAmount, bank: bank });
                    }
                }
            }

            if (Object.keys(nextProps.subscription.errors).length > 0) {
                const { subscription } = nextProps.subscription.errors;
                this.setState({ subscription });
            }

            if (nextProps.errors.chungdam) {
                toast.error(t("pending deposit"), {
                    className: "toast toast-error"
                });
            }

            if (nextProps.errors.sender) {
                this.setState({ error: true });
                this.setState({ success: false });
            }

            // if(nextProps.hotlinkPromotions){
            //     const d = new Date();
            //     let day = d.getDay()
            //     if(nextProps.hotlinkPromotion){
            //         const days = nextProps.hotlinkPromotion.RecurringDays.map((data) => {
            //             return data.DayNumber;
            //         })
            //         if(!this.props.hasPendingSubcription && !this.props.loadingPendingSubscription) {
            //             if(nextProps.hotlinkPromotion.RecurringDays.length !== 0){
            //                 if(days.includes(day)){
            //                     this.props.setDepositPromotionBonus(nextProps.hotlinkPromotion.PromotionID, nextProps.hotlinkPromotion);
            //                 }
            //             } else {
            //                 this.props.setDepositPromotionBonus(nextProps.hotlinkPromotion.PromotionID, nextProps.hotlinkPromotion);
            //             }
            //         }
            //     }
            // }

            if (nextProps.errors.amount) {
                this.setState({ error: true });
                this.setState({ success: false });
                this.setState(prevState => ({
                    errors: {
                        amount: nextProps.errors.amount[0].toLowerCase()
                    }
                }));
            }
            if (nextProps.errors.deposit !== undefined && nextProps.errors.deposit === 'pending deposit') {
                toast.error(t(nextProps.errors.deposit), {
                    className: "toast toast-error",
                });
                this.closeConfirm();
            } else if (nextProps.errors.subscription) {
                // toast.error(t(nextProps.errors.subscription), {
                //     className: "toast toast-error",
                // });
                this.setState({ ongoing_subcription: true });
            } else {
                if (nextProps.errors.status >= 500) {
                    if (!this.state.error_500) {
                        toast.error(t('error 500 toast'), {
                            containerId: 'F',
                            className: "toast toast-error"
                        });
        
                        this.setState({
                            error_500: true
                        });
        
                        setTimeout(() => {
                            this.setState({
                                error_500: false
                            })
                        }, 500)
                    }
                }
        
                if (nextProps.errors.status >= 400 && nextProps.errors.status < 500) {
                    this.setState({
                        error_400: true
                    });
                }
            }

            if (nextProps.resend.success) {
                toast.success(t("request resend complete"), {
                    className: "toast toast-success"
                });
            }

            if (nextProps.resendError.status === 400) {
                toast.error(t("sms code unsuccessful"), {
                    className: "toast toast-error"
                });
            }

            if (nextProps.errors.error ||
                nextProps.subscription.errors.hasOwnProperty('cancel')) {
                const msg = nextProps.subscription.errors.cancel.message;
                if (msg && msg.includes('pending bets')) {
                    toast.error(nextProps.t(msg.toLowerCase()), {
                        className: "toast toast-error",
                        onClose: () => {
                            this.setState({
                                containerId: 'A',
                                show: false,
                                loading: false
                            });
                            this.props.resetBonus();
                        }
                    });
                } else {
                    toast.error(nextProps.t('something went wrong'), {
                        className: "toast toast-error",
                        onClose: () => {
                            this.setState({
                                containerId: 'C',
                                show: false,
                                loading: false
                            });
                            this.props.resetBonus();
                        }
                    });
                }
                this.setState({ show: true });
            } else if (nextProps.subscription.cancel) {
                toast.success(nextProps.t('promotion success cancel'), {
                    containerId: 'B',
                    className: "toast toast-success"
                });
                this.props.resetBonus();
                this.props.resetOngoingPromotion();
                this.setState({ show: false });
            }

            if (Object.keys(nextProps.success).length > 0) {
                this.setState({ error: false});
                this.setState({ success: true});
                this.setState({ transactionID: nextProps.success.TransactionID})
            }
            this.setState({ provider: nextProps.provider });
        } catch (error) {
            console.log(error)
        }
    }

    selectWallet = () => {
        this.props.resetOngoingPromotion()
        this.props.resetPromotionMinDeposit()
        this.props.selectModalProvider({
            ID: 0,
            Name: "main wallet",
            Logo: mainWallet
        })
    }

    selectModalProvider = (provider) => {
        const { providers } = this.state

        this.props.resetBonus();
        this.props.selectModalProvider(provider);
        this.props.checkBonus({ provider: provider.ID });
    }

    onChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({ [name]: value });

        if (name !== 'bonus') {
            this.checkMin(value);
            this.checkMax(value);
            this.checkBank(value);
        } else {
            const id = { id: value };
            this.props.getPromotionMinDeposit(id);
        }
    }

    closeConfirm = () => {
        this.setState({ showConfirm: false });
        this.setState({ success: false, amount: "", tronMinError: false, tronMaxError: false, depositBlacklistError: false, depositCloseError: false, error_400: false });
        this.props.resetDeposit();
        this.props.resetResendDeposit();
        this.props.resetResendError();
        this.props.getTronBalance();
        this.props.setDepositPromotionBonus(null, null);
        this.checkBank(0);
        this.props.resetDepositError();
    }

    closeWincoin = () => {
        this.setState({ showWincoin: false });
        this.setState({ success: false, amount: "", tronMinError: false, tronMaxError: false, depositBlacklistError: false, depositCloseError: false, error_400: false });
        this.props.resetDeposit();
        this.props.resetResendDeposit();
        this.props.resetResendError();
        this.props.getTronBalance();
        this.props.setDepositPromotionBonus(null, null);
        this.checkBank(0);
        this.props.resetDepositError();
    }

    showConfirm = () => {

        this.setState({ validated: true });
        let amount = this.state.amount || 0;
        amount = amount.toString().replace(/,/g, "");
        amount = realAmount(Math.trunc(amount))
        const min = this.checkMin(amount);
        const max = this.checkMax(amount);

        if (this.form.checkValidity() && ! min && ! max) {
            this.setState({ errors: {}});
            this.setState({showConfirm:true})
        }

    }

    onClick = () => {
        this.setState({
            amount: null,
            minError: false,
            maxError: false,
        });
    }

    checkMax = (amount) => {
        const maxAmount = this.state.maxAmount;
        if (realAmount(amount) > maxAmount) {
            this.setState({ maxError: true }); return true;
        } else {
            this.setState({ maxError: false }); return false;
        }
    }

    checkMin = (amount) => {
        const minAmount = this.state.minAmount;
        if (this.state.bonus === null) {
            if (realAmount(amount) < minAmount) {
                this.setState({ minError: true }); return true;
            } else {
                this.setState({ minError: false }); return false;
            }
        } else {
            const minPromotionDepositAmount = this.state.selectedPromotion.PromotionCurrency.MinDeposit;
            if (realAmount(amount) < minAmount) {
                if (realAmount(amount) < minPromotionDepositAmount) {
                    this.setState({ minError: true }); return true;
                }
            } else {
                this.setState({ minError: false }); return false;
            }
        }
    }

    checkBank = (amount) => {
        let bank = this.state.bank;
        let localBank = []
        let bankID = 0;
        if (bank.length > 0) {
            for (let i = 0; i < bank.length; i++) {
                if (realAmount(amount) >= realAmount(bank[i].MinDepositAmount) && realAmount(amount) <= realAmount(bank[i].MaxDepositAmount)) {
                    bankID = bank[i].BankAccountID;
                    localBank = bank[i]
                    break;
                }
            }
            this.setState({ bankID: bankID, localBank: localBank });
        }
        return bankID;
    }

    quickAddAmount = (amountToAdd) => {
        let currentAmount = this.state.amount || 0;
        currentAmount = currentAmount.toString().replace(/,/g, "");
        if (currentAmount) {
            currentAmount = Math.trunc(currentAmount);
        }
        if (String(currentAmount).match(/[a-z]/i) || currentAmount === "") {
            currentAmount = 0;
        }
        currentAmount = Math.trunc(currentAmount) + Math.trunc(amountToAdd);
        currentAmount = Math.trunc(currentAmount);
        this.checkMin(currentAmount);
        this.checkMax(currentAmount);
        this.checkBank(currentAmount);
        this.setState({ amount: currentAmount });
    }

    //determine payment type ID based on GET /playerDepositAccount error response
    getPaymentTypeID = () => {
        //if no error
        if(this.state.paymentOption === 1) {
            // if (this.state.depositOptionIDs.includes(3)) {
            //     return DEDICATED_BANK_ACCOUNT_PAYMENT_TYPE;
            // } else {
            //     return LOCAL_BANK_DEPOSIT_TYPE;
            // }
            return LOCAL_BANK_DEPOSIT_TYPE;
        } else if (this.state.paymentOption === 5){
            return WIN_COIN_PAYMENT_TYPE;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ validated: true });
        let amount = this.state.amount || 0;
        amount = amount.toString().replace(/,/g, "");
        amount = realAmount(Math.trunc(amount))
        const min = this.checkMin(amount);
        const max = this.checkMax(amount);
        const bankID = this.checkBank(amount);

        if (this.props.walletz.player.Status === 5) {
            this.setState({ depositBlacklistError: true });
        } else if (this.props.walletz.player.Status === 2) {
            this.setState({ depositCloseError: true });
        }

        if (this.form.checkValidity() && !min && !max) {
            this.setState({ submitDisabled: true });
            const payment = {
                amount: amount,
                sender: this.state.bankAccountName,
                provider: 0,
                bonus: this.state.bonus,
                bank: bankID,
                lang: window.localStorage.i18nextLng.slice(0, 2),
                paymentTypeID: this.getPaymentTypeID()
            }
            this.props.postDeposit(payment);
            this.setState({ validated: false });
            window.setTimeout(() => {
                this.setState({ submitDisabled: false });
            }, 1000)
        }
    };

    resetForm = () => {
        this.setState({ amount: "", validated: false, minError: false, maxError: false, errors: {} });
        this.props.resetDeposit();
        this.checkBank(0);
    };

    onResend = () => {
        let amount = this.state.amount || 0;
        amount = realAmount(amount);
        const bankID = this.checkBank(amount);

        const payment = {
            bank: bankID,
            amount: amount
        };
        this.props.postResend(payment);
        this.props.resetResendError();
    }

    checkDepositAccount = () => {

        if (this.state.depositOptionIDs.includes(CHUNG_DAM_PAYMENT_TYPE)) {
            return false;
        }
        if(this.state.paymentOption === 5) {
            return false;
        }

        return this.state.depositOptionIDs.includes(3) ? this.props.depositAccount.length === 0 ? true : false : false;
    }

    onClickPaymentHandler = (payment_option) => {
        setSelectOption(payment_option);
        this.setPaymentOption(payment_option);
    }

    onClickOfflineBankPaymentHandler = () => {
        setSelectOption(LOCAL_BANK_DEPOSIT);
        this.setPaymentOption(LOCAL_BANK_DEPOSIT)
    }

    onClickGetTronBalanceHandler = () => {
        this.props.resetDeposit();
        this.props.resetResendDeposit();
        this.props.getTronBalance();
    }

    resetPaymentOption = (paymentOption) => {
        setSelectOption(paymentOption);
        this.setState({ paymentOption });
    }


    setPaymentOption = (paymentOption) => {
        this.resetForm();
        this.props.resetResendDeposit();
        this.props.resetResendError();
        this.setState({ paymentOption })
    }
    render() {
        const { t } = this.props;
        // console.log(this.state.paymentOption)
        return (
            <div id="containerForm" className="mb-5">
                <h2 className="form-title">{t('deposit')}</h2>
                <div className="formContent">
                    <div className="DepositContentWrap">
                        <ToastContainer autoClose={8000} />
                        <form
                            className={this.state.validated ? "needs-validation was-validated" : ""}
                            noValidate method="post"
                            onSubmit={this.onSubmit.bind(this)}
                            ref={(form) => this.form = form}
                        >
                            <div className="row">
                                <div className="col-md-5">
                                    <LocalBankForm
                                        props={this.props}
                                        state={this.state}
                                        onChange={this.onChange}
                                        onClick={this.onClick}
                                        quickAddAmount={this.quickAddAmount}
                                        resetForm={this.resetForm}
                                        showConfirm={this.showConfirm}
                                        closeConfirm={this.closeConfirm}
                                        onResend={this.onResend}
                                        onSubmit={this.onSubmit}
                                        showDepositPromotion={this.showDepositPromotion}
                                        setDepositPromotion={this.setDepositPromotion}
                                        checkDepositAccount={this.checkDepositAccount}
                                        isBlacklisted={this.state.depositBlacklistError}
                                        isClosed={this.state.depositCloseError}
                                        onClickPaymentHandler={this.onClickPaymentHandler}
                                        closeWincoin={this.closeWincoin}
                                        setPaymentOption={this.setPaymentOption}
                                    />
                                </div>
                                {
                                    this.state.paymentOption === 1 ?
                                    <Provider select={this.selectModalProvider} />
                                    :
                                    <div class="col-md-7">
                                        <div class="accountInfo contentbox mb-7">
                                            <p class="title">{t('payment_description_heading_coin')}</p><br/>
                                            {ReactHtmlParser(t('payment_description_content_coin'))}
                                        </div>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Deposit.propTypes = {
    postDeposit: PropTypes.func.isRequired,
    postResend: PropTypes.func.isRequired,
};

Deposit.defaultProps = {
    providers: [],
    promotions: [],
    currency: { currency: {} },
    subscription: {
        promotion: {
            Title: ""
        }
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    player: state.auth.wallet.player,
    errors: state.payment.depositErrors,
    success: state.payment.depositResult,
    providers: state.promotions.providers,
    provider: state.payment.provider,
    promotions: state.promotions.promotions,
    isLoading: state.promotions.isLoading,
    currency: state.auth.wallet.player,
    deposit: state.payment.transaction.transaction,
    wallet: state.player.wallet,
    walletz: state.auth.wallet,
    products: state.products.result,
    subscription: state.bonuses,
    bankAccount: state.payment.transaction,
    promotionMinDeposit: state.promotions.promoMinDeposit,
    resend: state.payment.resend,
    resendError: state.payment.resendError,
    depositAccount: state.player.depositAccount,
    depositAccountErrors: state.player.depositAccountErrors,
    tron: state.payment.tron,
    depositOption: state.payment.paymentType,
    promotionID: state.promotions.promotionID,
    selectedPromotion: state.promotions.selectedPromotion,
    hasPendingSubcription: state.promotions.hasPendingSubcription,
    loadingPendingSubscription: state.promotions.loadingPendingSubscription,
    hotlinkPromotion: state.promotions.hotlinkPromotion,
    depositPromotions: state.promotions.depositPromotions
});

export default connect(
    mapStateToProps,
    {
        cancelSubscription,
        postDeposit,
        postResend,
        getProviders,
        getProductProviders,
        selectProvider,
        selectModalProvider,
        reloadWallet,
        resetDeposit,
        checkBonus,
        resetBonus,
        resetOngoingPromotion,
        getBankAccount,
        getPromotionMinDeposit,
        resetPromotionMinDeposit,
        resetResendDeposit,
        resetResendError,
        getDepositAccount,
        clearDepositAccount,
        getTronBalance,
        resetDepositError,
        getDepositOption,
        getDepositPromotions,
        setDepositPromotionBonus,
        checkDepositPromotion,
        setDepositHotlink
    }
)(withRouter(withTranslation()(Deposit)));

