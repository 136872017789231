import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import Error from "../../common/error";
import { resetPlayerError, postPassword } from "../../../actions/player";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../../common/SuccessAlert";
class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            success: {},
            confirmPassword: "",
            isSubmitted: false,
            success3: false,
            validated3: false,
            type: "password",
            type2: "password",
            type3: "password",
            currentPassword: "",
            password: ""
        };

        this.showHide = this.showHide.bind(this);
    }

    componentDidMount() {
        // this.props.history.listen((location, action) => {
        //     if (location.pathname.includes("profile")) {
        //         this.props.resetPlayerError();
        //     }
        // });
        this.props.resetPlayerError();

        if (!this.props.player) return false;

        this.componentWillReceiveProps(this.props);
    }

    resetFields() {
        this.setState({password: ""});
        this.setState({currentPassword: ""});
        this.setState({confirmPassword: ""});
    }

    componentWillReceiveProps(nextProps) {
        const { isSubmitted } = this.state;

        const errors = nextProps.errors;

        this.setState({ errors });

        try {
            if (nextProps.success && isSubmitted) {
                if (
                    nextProps.success.message ===
                    "Player password updated succesfully"
                ) {
                    this.setState({ success3: true });
                    this.resetFields();
                    setTimeout(() => {
                        this.setState({ success3: false });
                        this.setState({ isSubmitted: false });
                    }, 5000);
                    
                }
            }
        } catch (error) {
            nextProps.showError();
        }
    }

    showHide = (param) => (e) => {
        e.preventDefault();

        switch (param) {
            case "type":
                this.setState({
                    type: this.state.type === "input" ? "password" : "input"
                });
                break;
            case "type2":
                this.setState({
                    type2: this.state.type2 === "input" ? "password" : "input"
                });
                break;
            default:
                this.setState({
                    type3: this.state.type3 === "input" ? "password" : "input"
                });
        }
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onSubmit3 = (e) => {
        e.preventDefault();

        this.setState({ isSubmitted: true });

        const player = {
            currentPassword: this.state.currentPassword,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        };
        this.setState({ validated3: true });
        if (this.form3.checkValidity()) {
            this.props.postPassword(player);
            this.setState({ validated3: false });
        }
    };

    notify = message => {
        toast.success(message, {
            className: 'toast toast-success profile-toast'
        });
    };

    render() {
        const { errors } = this.state;
        const { t } = this.props;
      
        return (
            <form
                noValidate
                onSubmit={this.onSubmit3.bind(this)}
                ref={(form) => (this.form3 = form)}
                className={classNames({
                    "needs-validation was-validated": this.state.validated3
                })}
            >
                <div class="accountInfo accountInfo-w365 mb-5">
                    {this.state.success3 ? <SuccessAlert translationCode="player password updated success" /> : null}
                    <div className="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("verify old password")} *</label>
                                <div class="input-group show_hide_password mb-4">
                                    <input
                                        type={this.state.type}
                                        className={classNames(
                                            "form-control dark",
                                            {
                                                "is-invalid":
                                                    errors.currentPassword
                                            }
                                        )}
                                        name="currentPassword"
                                        id="currentPassword"
                                        placeholder={t("verify old password")}
                                        onChange={this.onChange}
                                        value={this.state.currentPassword}
                                        required
                                    />
                                    <Error
                                        errors={errors}
                                        id="currentPassword"
                                    />
                                    <div 
                                        className="input-group-addon"
                                        onClick={this.showHide("type")}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <a href="">
                                            <i
                                                className={classNames("fa", {
                                                    "fa-eye-slash":
                                                        this.state.type ===
                                                        "password",
                                                    "fa-eye":
                                                        this.state.type ===
                                                        "input"
                                                })}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("enter new password")} *</label>
                                <div class="input-group show_hide_password mb-4">
                                    <input
                                        type={this.state.type2}
                                        className={classNames(
                                            "form-control dark",
                                            {
                                                "is-invalid":
                                                    errors.password
                                            }
                                        )}
                                        name="password"
                                        id="newpassword"
                                        placeholder={t(
                                            "enter new password"
                                        )}
                                        onChange={this.onChange}
                                        value={this.state.password}
                                        required
                                    />
                                    <div className="invalid-tooltip">
                                        {errors &&
                                        errors.password &&
                                        errors.password.length > 0
                                            ? ""
                                            : t("this field is required")}
                                        {(errors &&
                                            (errors.password &&
                                                errors.password[0] ===
                                                    "The password must be at least 6 characters.")) ||
                                            (errors &&
                                        (errors.password &&
                                                errors.password[0] ===
                                                "The password may not be greater than 12 characters."))
                                                ? t("password validation")
                                            : ""}
                                        {errors &&
                                        errors.password &&
                                        errors.password[0] ===
                                            "The password and confirm password must match."
                                            ? t("password do not match")
                                        : ""}
                                    </div>
                                    <div
                                        className="input-group-addon"
                                        onClick={this.showHide("type2")}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <a href="">
                                            <i
                                                className={classNames("fa", {
                                                    "fa-eye-slash":
                                                        this.state.type2 ===
                                                        "password",
                                                    "fa-eye":
                                                        this.state.type2 ===
                                                        "input"
                                                })}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("re-enter new password")} *</label>
                                <div class="input-group show_hide_password">
                                    <input
                                        type={this.state.type3}
                                        className={classNames(
                                            "form-control dark",
                                            {
                                                "is-invalid":
                                                    errors &&
                                                    errors.password &&
                                                    errors.password[0] === "The password and confirm password must match."
                                            }
                                        )}
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        placeholder={t("re-enter new password")}
                                        onChange={this.onChange}
                                        value={this.state.confirmPassword}
                                        required
                                    />
                                    <div className="invalid-tooltip">
                                        {this.state.confirmPassword === ""
                                        ? t("this field is required")
                                        : ""}
                                        {errors &&
                                        errors.password &&
                                        errors.password[0] ===
                                        "The password and confirm password must match."
                                        ? t("password do not match")
                                        : ""}
                                    </div>
                                    <div
                                        className="input-group-addon"
                                        onClick={this.showHide("type3")}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <a href="">
                                            <i
                                                className={classNames("fa", {
                                                    "fa-eye-slash":
                                                    this.state.type3 ===
                                                    "password",
                                                    "fa-eye":
                                                    this.state.type3 ===
                                                    "input"
                                                })}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mt-4">
                            <div class="action-btn-modal">
                                <div class="contactBtn">
                                    <button
                                        type="submit"
                                        className="btn btn-secondary float-right saveBtn"
                                    >
                                        {t("save")}
                                    </button>
                                    {/* <button
                                        type="reset"
                                        className="btn btn-dark float-right cancelBtn mr-3"
                                        onClick={() => this.resetFields()}
                                        hidden={this.state.currentPassword === "" || this.state.password === "" || this.state.confirmPassword === "" }
                                    >
                                        {t("cancel")}
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

Profile.propTypes = {
    postPassword: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    player: state.auth.wallet.player,
    errors: state.player.errors,
    success: state.player.result
});

export default connect(
    mapStateToProps,
    { postPassword, resetPlayerError }
)(withTranslation()(withRouter(Profile)));
