import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { displayAmount, realAmount } from '../../../../helpers';

import rebateWalletIcon from '../../../../assets/img/w365/rebate-wallet-icon.svg';
import mainWalletIcon from '../../../../assets/img/w365/main-wallet-icon.svg';
import middleArrowIcon from '../../../../assets/img/w365/transfer-arrow.svg';
import { Loader } from '../../../common';
import { rebateTransferModalToggle, processRebateTransfer } from '../../../../actions/rebate';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

const RebateTransfer = ({ mainWalletAmount, rebateWalletAmount }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const rebateTransferData = useSelector(state => state.rebate.transferData);

    const closeModal = () => dispatch(rebateTransferModalToggle(false));

    useEffect(() => {
        if(rebateTransferData.transferData.success !== undefined) {
            if(rebateTransferData.transferData.success) {
                // toast.success(t('rebate transfer success'), {
                //     className: "toast toast-success"
                // });
            } else {
                toast.error(t('error encountered processing'), {
                    className: "toast toast-error"
                });
            }
        }
    }, [rebateTransferData.transferData]);

    return (
        <>
            <ToastContainer autoClose={8000} />
            <div
                style={{display: rebateTransferData.showModal ? 'block' : 'none'}}
                id="rebateTransferModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="rebateTransferModal"
                aria-hidden="true"
            >
                <Modal centered show={rebateTransferData.showModal} onHide={closeModal} size="lg">
                    <Modal.Header>
                        <p className="modal-title text-center">{t('rebate transfer')}</p>
                        <button type="button" className="close" onClick={closeModal}><span aria-hidden="true">&times;</span></button>
                    </Modal.Header>
                    <Modal.Body className="rebate-transfer-modal">
                        <div className="d-flex justify-content-between">
                            <div className="wallet-box-container">
                                <div className="transfer-text">{t('transfer from')}:</div>
                                <div className="wallet-box">
                                    <div className="mr-3"><img src={rebateWalletIcon} alt="" /></div>
                                    <div>
                                        <div className="wallet-name">{t('rebate wallet')}</div>
                                        <div className="wallet-amount">{rebateWalletAmount}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="wallet-arrow">
                                <img src={middleArrowIcon} alt="" />
                            </div>
                            <div className="wallet-box-container">
                                <div className="transfer-text">{t('transfer to')}:</div>
                                <div className="wallet-box">
                                    <div className="mr-3"><img src={mainWalletIcon} alt="" /></div>
                                    <div>
                                        <div className="wallet-name">{t('main wallet')}</div>
                                        <div className="wallet-amount">{mainWalletAmount}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div><p className="info-text">{t('minimum rebate redemption').replace('{amount}', displayAmount(rebateTransferData.data.required_balance))}</p></div> */}
                        <div><p className="info-text">{t('caution to rebate text')}</p></div>
                        <div className="text-center transfer-btn-container">
                            <button
                                className="btn btn-success"
                                onClick={() => dispatch(processRebateTransfer({amount: realAmount(rebateWalletAmount)}))}
                                disabled={rebateTransferData.transferProcessing || !rebateTransferData.data.success}
                            >
                                {rebateTransferData.transferProcessing ? <><Loader style={{ width: "18px" }}/>&nbsp;&nbsp;</> : null}{t('transfer')}
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default RebateTransfer;
