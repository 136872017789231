import React from 'react';
import { useSelector } from 'react-redux';
import classname from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { player } from '../../../../selectors/auth';
import { displayAmount, realAmount } from '../../../../helpers';
import BtnLoader from '../../../common/BtnLoader';
import SuccessAlert from "../../../common/SuccessAlert";
import DangerAlert from "../../../common/DangerAlert";
import ReactHtmlParser from "react-html-parser";

const ConfirmWithdrawal = ({show, amountReceived, bankName, accountNumber, bankAccountName, handleClose, onSubmit, success, errors, propsSuccess, submitDisabled, isBlacklisted, isSuspended, isClosedAccount, errorStatusCode }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div
                style={{
                    display: show ? "block" : "none"
                }}
                id="DepositModal" 
                tabIndex={-1} 
                role="dialog" 
                aria-labelledby="DepositModal" 
                aria-modal="true">
                <Modal
                    centered
                    show={show}
                    onHide={handleClose}
                    
                className={classname("modal-confirm",{
                })}
                dialogClassName='modal-width'
                    >
                    <Modal.Header>
                        <h5 className="modal-title" id="LoginModal">
                            {
                                success ? 
                                    t('withdrawal sent')
                                : errors || isBlacklisted ?
                                    t('withdrawal request failed')
                                :
                                    t('check the withdrawal information')
                            }
                        </h5>
                        <button type="button" className="close" onClick={() => handleClose()} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className='row'>
                        {
                            success ?

                                <div className="col-12 accountInfo  mb-12" id="deposit-message">
                                    <div className="depSentContent">
                                        <div className="depSent">
                                            <SuccessAlert translationCode="withdrawal sent" />
                                        </div>

                                        <div className="p-1 col-12">
                                            <div className='row'>
                                                <div className="mb-2 col-4 colgray" style={{fontWeight:'bold',textTransform: 'uppercase'}} >{t('transaction id')}:</div> 
                                                <div className="mb-2" style={{fontWeight:'700'}}>{propsSuccess.TransactionID}</div> 
                                            </div>

                                            <div className='row' style={{marginTop:'20px'}}>
                                                <div className="mb-2 col-12" >{t('withdrawal sent description')}</div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : errors || isBlacklisted || isSuspended || isClosedAccount ?
                            <div class="col-md-12 accountInfo mb-12" id="deposit-message">
                            <div class="depSentContent">
                                {/* <DangerAlert translationCode='withdrawal request failed' /> */}
                                <div className="p-1 col-12">
                                    <div id='containerForm'>
                                        <div className='row' style={{marginTop:'0px'}}>
                                            <div className="mb-2 col-12" >{t('withdrawal request was unsuccessful')}</div> 
                                        </div>
                                        <div className='row' style={{marginTop:'7px'}}>
                                            <div className="mb-2 col-3 colgray" style={{fontWeight:'bold'}} >{t('error code')}:</div> 
                                            <div className="mb-2 ">{errorStatusCode}</div> 
                                        </div>
                                        <h3 class="requestFailedTitle" style={{textAlign:'center'}}>{t('need help web')}</h3>
                                        <p class="requestFailedSub cs-support-withdrawal"><span>{t('our cs support is available 24 hours')}</span></p>
                                    </div>
                                </div>
                                
                                <div>
                                    <div class="row no-gutters" style={{marginTop:'80px'}}>
                                        <div class="col-md-3 offset-md-3">
                                            <div style={{textAlign:'center'}}>
                                                <div className='icon-bg-withdrawal' >
                                                    <svg className='icon-withdrawal' width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'23px'}}>
                                                        <path d="M34.8 22.8C34.5 22 33.6 21.7 32.9 22C32.1 22.3 31.8 23.2 32.1 23.9L33.9 28.2L28.7 25.9C28.3 25.7 27.9 25.7 27.5 25.9C25.7 26.7 23.8 27 21.9 27C14 27 8.6 20.8 8.6 15C8.6 8.4 14.6 3 21.9 3C29.2 3 35 8.4 35 15C35 15.5 35 16.1 34.9 16.7C34.8 17.5 35.3 18.3 36.1 18.4C36.9 18.5 37.7 18 37.8 17.2C37.9 16.5 38 15.7 38 15.1C38 11.1 36.3 7.3 33.2 4.4C30.1 1.5 26.1 0 21.7 0C17.3 0 13.3 1.5 10.3 4.3C7.5 6.8 5.9 10.1 5.5 13.7C2.1 16 0 19.7 0 23.7C0 26.2 0.8 28.5 2.3 30.6L0.1 36C-0.1 36.6 0 37.2 0.4 37.6C0.7 37.9 1.1 38 1.4 38C1.6 38 1.8 38 2 37.9L8.2 35.1C9.8 35.7 11.4 36 13.2 36H13.3C15.8 36 18.2 35.3 20.3 34.1C22.2 33 23.7 31.5 24.8 29.7C25.9 29.5 26.9 29.2 27.9 28.8L35.9 32.3C36.1 32.4 36.3 32.4 36.5 32.4C36.9 32.4 37.2 32.3 37.5 32C37.9 31.6 38.1 30.9 37.8 30.4L34.8 22.8ZM13.3 33C11.7 33 10.3 32.7 8.9 32.1C8.5 31.9 8.1 31.9 7.7 32.1L4.2 33.6L5.3 30.8C5.5 30.3 5.4 29.7 5.1 29.3C3.7 27.8 3 25.8 3 23.7C3 21.3 4 19.1 5.7 17.4C6.2 20.4 7.8 23.2 10.1 25.4C13 28.2 16.8 29.8 20.9 30C19 31.9 16.2 33 13.3 33Z" fill="#33C16C"/>
                                                        <path d="M21.7 16.6C22.5 16.6 23.2 15.9 23.2 15.1C23.2 14.3 22.5 13.6 21.7 13.6C20.9 13.6 20.2 14.3 20.2 15.1C20.2 15.9 20.9 16.6 21.7 16.6Z" fill="#33C16C"/>
                                                        <path d="M27.6 16.6C28.4 16.6 29.1 15.9 29.1 15.1C29.1 14.3 28.4 13.6 27.6 13.6C26.8 13.6 26.1 14.3 26.1 15.1C26.1 15.9 26.8 16.6 27.6 16.6Z" fill="#33C16C"/>
                                                        <path d="M15.7 16.6C16.5 16.6 17.2 15.9 17.2 15.1C17.2 14.3 16.5 13.6 15.7 13.6C14.9 13.6 14.2 14.3 14.2 15.1C14.3 15.9 14.9 16.6 15.7 16.6Z" fill="#33C16C"/>
                                                    </svg>
                                                </div>
                                                <br/>
                                                <p className='iconLabel-withdrawal'>{t('live chat')}</p>
                                                <p className='iconSubLabel-withdrawal'>{t("cs support")}</p>
                                                <a href="#chat" target="_blank"></a>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div style={{textAlign:'center'}}>
                                                <div className='icon-bg-withdrawal'>
                                                    <svg className='icon-withdrawal' width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'21px',marginTop:'5px'}}>
                                                        <path d="M36.9943 0H2.00571C0.891429 0 0 0.900433 0 2.02597V23.974C0 25.0996 0.891429 26 2.00571 26H36.9943C38.1086 26 39 25.0996 39 23.974V2.02597C39 0.900433 38.1086 0 36.9943 0ZM36.2143 1.4632L20.6143 13.0563C20.28 13.2814 19.9457 13.3939 19.5 13.3939C19.0543 13.3939 18.6086 13.2814 18.3857 13.0563L2.78571 1.4632H36.2143ZM27.8571 13.9567L36.3257 24.4242H2.56286L11.0314 13.9567C11.2543 13.619 11.2543 13.1688 10.92 12.9437C10.5857 12.7186 10.14 12.7186 9.91714 13.0563L1.44857 23.4113V2.47619L17.3829 14.4069C17.94 14.8571 18.72 15.0823 19.3886 15.0823C20.0571 15.0823 20.8371 14.8571 21.3943 14.4069L37.44 2.47619V23.4113L28.9714 13.0563C28.7486 12.7186 28.1914 12.7186 27.9686 12.9437C27.7457 13.1688 27.6343 13.619 27.8571 13.9567Z" fill="#33C16C"/>
                                                    </svg>
                                                </div>
                                                <br/>
                                                <p className='iconLabel-withdrawal'>{t("email")}</p>
                                                <p className='iconSubLabel-withdrawal'>{t('cs support email')}</p>
                                                <a href="mailto:cskr@w365.com" target="_blank"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row modal-flex">
                                    <div className="col-md-5 qr_footer qr-padding text-right">
                                        <a href="https://t.me/cskr15bet" target="_blank" style={{padding: 0, display: 'initial'}}><img className="pb-2" src="/assets/img/w365/icons/contact-us/tg_qr.png"/></a>    
                                    </div>
                                    <div className="cold-md-7 qr-text">
                                        {ReactHtmlParser(t('telegram click mobile'))} 
                                    </div>
                                </div>
                            </div>
                        </div>
                            :
                            <div className="col-12">
                                <div className='row'>
                                    <div className="mb-2 col-4 colgray" style={{textTransform:'uppercase'}}>{t('amount received')}:</div> 
                                    <div className="mb-2 col-4" >{displayAmount(amountReceived)}</div> 
                                </div>

                                <div className='row'>
                                    <div className="mb-2 col-4 colgray" style={{textTransform:'uppercase'}}>{t('bank name')}:</div> 
                                    <div className="mb-2 col-4" >{bankName}</div> 
                                </div>

                                <div className='row'>
                                    <div className="mb-2 col-4 colgray" style={{textTransform:'uppercase'}}>{t('account name')}:</div> 
                                    <div className="mb-2 col-4" >{bankAccountName}</div> 
                                </div>
                                <div className='row'>
                                    <div className="mb-2 col-4 colgray" style={{textTransform:'uppercase'}}>{t('account number')}:</div> 
                                    <div className="mb-2 col-4" >{accountNumber}</div> 
                                </div>
                            </div>
                        }
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='footer-padding'>
                        <button onClick={handleClose} className={classname("btn btn-success btn-border")}>
                            {
                                success || (errors || isBlacklisted || isSuspended || isClosedAccount) ? 
                                    t('close')
                                : t('cancel')
                            }
                        </button>
                        {
                            success || (errors || isBlacklisted || isSuspended || isClosedAccount) ? '' : <button onClick={onSubmit} disabled={submitDisabled} className={classname("btn btn-secondary btn-loader")}>{ t('confirm') }</button>

                        }
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default ConfirmWithdrawal;
