import React from "react";
import classNames from "classnames";
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const List = ({messages, onRead, ownProps}) => {
    const { t } = ownProps;
    let currentPage = useSelector(state => state.player.page);
    currentPage = currentPage === null ? 1 : currentPage;
    const { unreadMessage } = ownProps;
    const unReadMessageCount = unreadMessage && unreadMessage.unread_message_replies_count !== undefined ? unreadMessage.unread_message_replies_count : 0;

    return (
        <div className="row mt-4 message-section">
            <div className="col-lg-12">
                <table className="table table-striped table-dark">
                    <thead>
                        <tr>
                            <th className="txt-center num" scope="col">{t("no.")}</th>
                            <th scope="col">{t('message')}</th>
                            <th scope="col" className="date">{t('date')}</th>
                            <th className="txt-center action" scope="col">{t('action')}</th>
                        </tr>
                    </thead>
                    { messages.length > 0 ?
                            (<tbody>
                                { messages.map((message, index) => {
                                    return (
                                        <tr key={message.MessageID} className={message.unread_replies_count > 0 ? 'active' : ''}>
                                            <td className="txt-center">{(currentPage - 1) * 5 + (index + 1)}</td>
                                            <td>{message.Subject}</td>
                                            <td>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="text-left">
                                                            {message.DateCreated} 
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center text-uppercase">
                                                <a
                                                    href="#"
                                                    className={classNames("btn small-caps btnMsg message_id_1_link",{
                                                        "btn-secondary": message.Status === "Read",
                                                        "btn-success reset": message.Status === "View"
                                                    })}
                                                    onClick={() => onRead(message.MessageID)}
                                                >
                                                    { t(String(message.Status).toLowerCase()) }
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                }) }
                            </tbody>) :
                            (<tbody className="text-center">
                            <tr>
                                <td colSpan="12">
                                    <h4>{t('no results found')}</h4>
                                </td>
                            </tr>
                        </tbody>)
                    }
                </table>
            </div>
        </div>
    )
}

export default withTranslation() (List);
