import React, { useState, useEffect , useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getCurrencies } from "../../../actions/registration";
import { resetPostContact } from "../../../actions/partner";
import ReactHtmlParser from "react-html-parser";

const Contact = ({contact}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [subject, setSubject] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [messages, setMessage] = useState('');
    const [buttonToggle, setButtonToggle] = useState(true);
    const [success, setSuccess] = useState(false);
    const [validate, setValidate] = useState(false);
    const [form, setForm] = useState();
    const [sub, setSub] = useState(false);
    const { message }  = useSelector((state) => state.partner);
    const { errors }  = useSelector((state) => state.partner);
    const { status }  = useSelector((state) => state.partner);
    const [err, setErr] = useState('');
    const subjecttextInput = useRef(null);
    const phonetextInput = useRef(null);
    const nametextInput = useRef(null);
    const emailtextInput = useRef(null);
    const messagestextInput = useRef(null);
    
    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const data = {
            'subject': subject,
            'phone': '010' + phone,
            'name': name,
            'email': email,
            'message': messages,
            'deviceUrl': window.location.href
        };
        setSub(true);
        setValidate(true)
        setSuccess(true);
        contact(data);
        
    }

    useEffect(() => {
        if(status === 200) {
            setSubject('');
            setPhone('');
            setName('');
            setEmail('');
            setMessage('');
            setButtonToggle(true);
            setValidate(false);
            setSub(false);
            setErr('');
            getSucessTimeout();
        }
    },[status])

    const getSucessTimeout = () => {
        setTimeout(() => {
            setSuccess(false);
            dispatch(resetPostContact(false))
        }, 3000);
    }

    const onChange = (e) => {
        switch(e.target.name) {
            case 'subject':
                setSubject(e.target.value);
            break;
            case 'phone':
                setPhone(e.target.value);
            break;
            case 'name':
                setName(e.target.value);
            break;
            case 'email':
                setEmail(e.target.value);
            break;
            case 'message':
                setMessage(e.target.value);
            break;
            default:
                setButtonToggle(true);
        }
    }

    useEffect(() => {
        if (subject !== '' && phone !== '' && name !== '' && email !== '' && messages !== '') {
            setButtonToggle(false);
        } else {
            setButtonToggle(true);
        }
    })

    useEffect(() => {
        dispatch(resetPostContact(false))
    },[])

    useEffect(() => {
        setErr(errors);
    },[errors])

    return (
        <section className="animation-element contact-section padding-top" id="contactus">
            <div className="container">
                <div class="row align-items-center">
                    <p class="title">{t('contact us')}</p>
                    <p class="sub-title">{t('contact cs')}</p>

                    <div class="container">
                        <div class="row align-items-start justify-content-center">
                            <div class="col">
                                <div class="box-container highlighted">
                                    <div class="col col-contacts-partners">
                                        <div class="circle">
                                            <div class="icon livechat-icon"></div>
                                        </div>
                                        <p class="p-icon-title">{t('live chat')}</p>
                                        <p class="p-icon-description">{t('cs support')}</p>
                                    </div>
                                    <div class="col col-contacts-partners">
                                        <div class="circle">
                                            <div class="icon kakao-icon"></div>
                                        </div>
                                        <p class="p-icon-title">{t('kakao talk')}</p>
                                        <p class="p-icon-description">{t('+82XXXXXXXX')}</p>
                                    </div>
                                    <div class="col col-contacts-partners">
                                        <div class="circle">
                                            <div class="icon email-icon"></div>
                                        </div>
                                        <p class="p-icon-title">{t('email')}</p>
                                        <p class="p-icon-description">{t('cskr@w365.com')}</p>
                                    </div>
                                    <div class="col col-contacts-partners">
                                        <div class="circle">
                                            <div class="icon hotline-icon"></div>
                                        </div>
                                        <p class="p-icon-title">{t('hotline')}</p>
                                        <p class="p-icon-description">{t('+82XXXXXXXX')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
