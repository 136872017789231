import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Notice from "./modals/powerball";
import { useSelector } from "react-redux";
import { useDispatch } from "redux-react-hook";
import { getTemplateContent, getBrandGameSetup } from "../../../../actions/page";
import { findToken } from "../../../../config/api";

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [active, setActive] = useState(1);
    const [show, setShow] = useState(false);
    const vip = window.location.pathname.includes("vip");
    const promotions = window.location.pathname.includes("promotions");
    const powerball = window.location.pathname === "/powerball";
    const livesports = window.location.pathname.includes("live-sports");
    const mobile = window.location.pathname.includes("mobile");
    const partners = window.location.pathname.includes("partners");
    const virtual = window.location.pathname.includes("virtual-sports");
    const esports = window.location.pathname === "/e-sports";
    const sports = window.location.pathname === "/bti-sports";
    const liveCasino = window.location.pathname === "/live-casino" || /^\/live-casino\/play\/.+$/.test(window.location.pathname);
    const  is_root = window.location.pathname === "/";
    const slots = window.location.pathname === "/slots";
    const minigame = window.location.pathname === "/minigame/cockfighting";
    const bettingRules = window.location.pathname === "/sports-betting-game-rules";
    const betconstruct = window.location.pathname === "/betconstruct";
    const handleClick = (id) => {
        setActive(id)
    }

    const showNotice = () => {
        dispatch(getTemplateContent({slug:'notice', lang: lang[0]}));
        setShow(true)
    }

    const closeNotice = () => {
        setShow(false)
    }

    const { language } = i18n;
    const dispatch = useDispatch();
    let lang = [];

    if(language !== undefined) {
        lang = language.split("-")
    } else {
        lang = ['ko-KR'];
    }

    // const content = useSelector((state) => state.page.content);
    const brandGameSetup = useSelector((state) => state.page.brandGameSetup);


    useEffect(() => {
        dispatch(getBrandGameSetup({brandID:5}))
    }, [])

    const isShow = (ProductID) => {
        if (brandGameSetup !== undefined && brandGameSetup.length > 0) {
            return brandGameSetup.find(el => {
                return el.ProductID === ProductID
            });
        }
    }

    const isShowSportProvider = (ProviderID) => {
        if (brandGameSetup !== undefined && brandGameSetup.length > 0) {
            return brandGameSetup.find(el => {
                return el.ProviderID === ProviderID
            });
        }
    }

    const showLoginModal = (e) => {
        if(localStorage.getItem('appToken') === null) {
            document.getElementById('login-btn').click();
            e.preventDefault();
        }
    }

    return (
        <React.Fragment>
            <div className="col-auto">
                <div className="website-logo">
                    <Link to="/">
                        <img className="ImgFluid" src="/assets/img/15BET-Logo.png" alt="Logo"/>
                    </Link>
                </div>
            </div>
            
            <div className="col-auto px-2">
                <ul className="main_nav">
                    <li className={classnames("",{"d-none":isShowSportProvider(32) !== undefined ? isShowSportProvider(32).length !== 0 ? false : true :true})}>
                        <Link to="/betconstruct" className={classnames("",{"active": betconstruct })}>{t('betconstruct')}</Link>
                    </li>
                    <li className={classnames("",{"d-none":isShow(2) !== undefined ? isShow(2).length !== 0 ? false : true :true})}>
                        <Link to="/live-casino" className={classnames("",{"active": liveCasino })}>{t("live casino")}</Link>
                    </li>
                    <li className={classnames("",{"d-none":isShowSportProvider(21) !== undefined ? isShowSportProvider(21).length !== 0 ? false : true : true })}>
                        <Link to="/bti-sports" className={classnames("",{"active": sports })}>{t("bti")}</Link>
                    </li>
                    {/* <li className={classnames("",{"d-none": isShowSportProvider(25) !== undefined ? isShowSportProvider(25).length !== 0  ? false : true :true})}>
                        <Link to="/live-sports" className={classnames("",{"active": livesports })}>{t("betradar")}</Link>
                    </li> */}
                    {/* <li className={classnames("",{"d-none":isShow(9) !== undefined ? isShow(9).length !== 0 ? false : true :true})}>
                        <Link to="/e-sports" className={classnames("",{"active": esports })}>{t("e-sports")}</Link>
                    </li> */}
                    <li className={classnames("",{"d-none":isShow(3) !== undefined ? isShow(3).length !== 0 ? false : true :true})}>
                        <Link to="/slots" className={classnames("",{"active": slots })}>{t("slots")}</Link>
                    </li>
                    {/* <li className={classnames("",{"d-none":isShowSportProvider(30) !== undefined ? isShowSportProvider(30).length !== 0 ? false : true :true})}>
                        <Link to="/minigame/cockfighting" className={classnames("",{"active": minigame })} onClick={showLoginModal}>{t("cockfight")}</Link>
                    </li> */}
                    {/* <li className={classnames("",{"d-none":isShow(10) !== undefined ? isShow(10).length !== 0 ? false : true :true})}>
                        <Link to="/powerball" className={classnames("",{"active": powerball })}>{t("powerball")}</Link>
                    </li> */}
                    <li>
                        {/* <Link to="/promotions" className={classnames("",{"active": promotions })} onClick={showLoginModal}>{t("promotions")}</Link> */}
                        <Link to="/promotions" className={classnames("",{"active": promotions })}>{t("promotions")}</Link>
                    </li>
                    <li>
                        <Link to="/vip" className={classnames("",{"active": vip })}>{t("vip club")}</Link>
                    </li>
                    <li>
                        <Link to="/sports-betting-game-rules" className={classnames("",{"active": bettingRules})}>{t("rules")}</Link>
                    </li>
                    {/* <li>
                        <Link to="/partners" className={classnames("pr-0",{"active": partners })}>{t("partners")}</Link>
                    </li> */}
                </ul>
            </div>
            {/* {
                content && content.content !== undefined ? <Notice content={content.content} show={show} handleClose={closeNotice} /> : ''
            } */}
        </React.Fragment>
    );
};

export default Navbar;
