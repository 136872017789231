import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const Promotion = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div class="col-auto pr-0">
            <div class="thumb-banner-section">
                <p class="div-title-2">{t("promotions")}</p>
                {data.map(promo => {
                    return (
                        <div class="row" key={`promotion-${promo.ID}`}>
                            <div class="col-auto thumb-img">
                                <img src={process.env.REACT_APP_BO_STORAGE+promo.ThumbnailPath} alt="" />
                            </div>
                            <div class="col thumb-text">
                                <p class="title">{promo.Title}</p>
                                <p>{promo.ShortDescription}</p>
                                <Link to={`/myaccount/deposit/${promo.PromotionID}`} className="btn btn-secondary btn-sm">{t('join now')}</Link>
                            </div>
                        </div>
                    );
                })}
                {data.length > 4 ? <Link className="btn btn-dark btn-sm see-all" to="/promotions">{t("view all")}</Link> : null}
            </div>
        </div>
    );
}

export default Promotion;
