import React, { useEffect, useState } from "react";
import { Link, useHistory} from "react-router-dom";
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

import { loadSlots } from '../../../actions/slot';
import { getGames } from '../../../api/slot'; 
import { loadCasinoGames } from '../../../actions/casino';
import { getCategories } from '../../../api/casino';

import SlotsThumb from './SlotsThumb';
import CasinoThumb from './CasinoThumb';
import Loading from './Loading';
import BannerSlider from './BannerSlider';
import Popup from './popup';

const Main = () => {
    const { t, ready } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [slots, setSlots] = useState([]);
    const [casinos, setCasinos] = useState([]);
    const [loadingCasino, setLoadingCasino] = useState(true);
    const [loadingSlots, setLoadingSlots] = useState(true);

    let lang = [];

    if(localStorage.getItem('i18nextLng') === null) {
        lang = ['en'];
    } else {
        lang = localStorage.getItem('i18nextLng').match(/^[a-z]+/);
    }

    const handleClick = (url) => {
        history.push(url)
    }
    
    useEffect(() => {
        getGames({product:3, page: 'homepage'}).then(response => {
            let fetchedSlots = response.data.data;
            let cleanFetchSlots = [];
            let x = 0;
            let i = 0;
            
            for(x in fetchedSlots) {
                if(fetchedSlots[x] !== null) {
                    const NameLang = JSON.parse(fetchedSlots[x].NameLang);
                    let nameText = NameLang.en;

                    if(typeof NameLang.ko === 'string' && lang[0] === 'ko') {
                        nameText = NameLang.ko;
                    }

                    cleanFetchSlots[i] = fetchedSlots[x];
                    cleanFetchSlots[i].nameText = nameText;
                    cleanFetchSlots[i].Type = t(cleanFetchSlots[i].Type.toLowerCase());
                    i++;
                }
            }

            setSlots(cleanFetchSlots);
            dispatch(loadSlots(cleanFetchSlots));
            setLoadingSlots(false);
        });

        getCategories({product: 2}).then(response => {
            const fetchedGames = response.data.data;
            const filter = fetchedGames.filter(data => data.Featured === 1 || data.NewGame === 1);
            setCasinos(filter);
            dispatch(loadCasinoGames(filter));
            setLoadingCasino(false);
        });
    }, []);

    useEffect(() => {
        window['generateHomeSlotsPrevNextButtons'](slots.length);
    }, [slots]);

    useEffect(() => {
        window['generateHomeCasinoPrevNextButtons'](casinos.length);
    }, [casinos]);

    return (
        <>
            <div className="landing-page">
                <BannerSlider />
                {/* <img className="imgFluid" src="/assets/img/w365/landingpage/Home-Banner.jpg"/> */}
                <div className="container sub-banners">
                    {/* <div className="row">
                        <div className="col text-center">
                            { !ready ? <h2 class="headline"><span class="highlighted-text">EASY</span> SIGN-UP, <span class="highlighted-text">FAST</span> WITHDRAWALS</h2> : ReactHtmlParser(t("homepage header text")) }
                        </div>
                    </div> */}
                </div>
                <div className="casino-home">
                    <div className="container Slot-target" style={{padding: '30px'}}>
                        <section>
                            <Row>
                                <Col md="6"><h4 className="title-slot">{t('top live casino')}</h4></Col>
                                <Col md="6" className="d-flex align-items-center">
                                    <div className="d-flex justify-content-end w-100">
                                        <div>
                                            <img className="casino-home-button d-none" src="/assets/img/w365/arrow-left.svg" alt="" />&nbsp;&nbsp;
                                            <img className="casino-home-button d-none" src="/assets/img/w365/arrow-right.svg" alt="" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{padding: '0 5px'}}>
                                    <div style={{overflow:'hidden'}}>
                                        <div className="d-flex w-100 casino-picks">
                                            {loadingCasino ? <Loading /> : <>{casinos.length > 0 ? casinos.map(casino => <CasinoThumb key={casino.Name} {...casino} />) : <p>{t('nothing to display')}</p>}</>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
                <div className="container slots-home Slot-target" style={{padding: '30px'}}>
                    <section>
                        <Row>
                            <Col md="6"><h4 className="title-slot">{t('top slots')}</h4></Col>
                            <Col md="6" className="d-flex align-items-center">
                                <div className="d-flex justify-content-end w-100">
                                    <div>
                                        <img className="slots-home-button d-none" src="/assets/img/w365/arrow-left.svg" alt="" />&nbsp;&nbsp;
                                        <img className="slots-home-button d-none" src="/assets/img/w365/arrow-right.svg" alt="" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{padding: '0 5px'}}>
                                <div style={{overflow:'hidden'}}>
                                    <div className="d-flex w-100 slots-picks">
                                        {loadingSlots ? <Loading /> : <>{slots.length > 0 ? slots.map(slot => <SlotsThumb key={slot.Name} {...slot} />) : <p>{t('nothing to display')}</p>}</>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
            <Popup/>
        </>
    )
}

export default Main;