import React, { Component }  from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Navbar from "./pages/navbar";
import Login from "./modals/login";
import Header1 from "./pages/login";
import Header2 from "./pages/loggedInHeader";

import { getActiveProviders } from "../../../actions/provider";

class Header extends Component {
    state = {
        showLogin: false
    };

    handleClose() {
        this.setState({ showLogin: false });

        if(window.location.pathname === '/minigame/cockfighting') {
            this.props.history.push('/');
        }
    }

    handleOpen() {
        this.setState({ showLogin: true });
    }

    render() {
        const token = localStorage.getItem("appToken");

        return (
            <header id="header">  
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <Navbar/>
                        { token ? 
                        <Header2/>
                        :
                        <Header1 handleOpen={this.handleOpen.bind(this)}/>
                        }
                    </div>
                </div>
                <Login show={this.state.showLogin} handleClose={this.handleClose.bind(this)} />
            </header>
        );
    }
}

Header.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps,{ getActiveProviders })(withRouter((Header)));
