import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { postPlay } from '../../../api/casino';
import { showSessionModal } from '../../../actions/session';
import BlackListedError from '../../common/BlackListedError';
import ReactHtmlParser from 'react-html-parser';

const Play = (props) => {
    //temporary code, needs to be removed once live
    const auth = useSelector(state => state.auth);
    const params = new URLSearchParams(props.location.search);
    const pathName = window.location.pathname.split('/');
    const provider = params.get('providerID');

    // if(auth.wallet.player.IsTestAccount === 0) {
    //     window.location.href = '/';
    // }
    //temporary code, needs to be removed once live
    const { t, i18n } = useTranslation();
    const [url, setUrl] = useState('');
    const [blacklistedError, setBlacklistedError] = useState(false);
    const [errors, setErrors] = useState(null);
    const casinoGames = useSelector(state => state.casino.games);
    const player = useSelector(state => state.auth.wallet);
    const dispatch = useDispatch();
    let selectedGame = casinoGames.filter(game => game.GameTypeCode === props.match.params.game_type_code)[0];
    const selectedProvider = useSelector(state => state.casino.selectedProvider);

    const { language } = i18n;
    let lang = '';
    
    if(language !== undefined) {
        lang = language.split('-')[0];
    } else {
        lang = 'ko';
    }
    
    // if(selectedGame === undefined) {
    //     window.location.href = '/live-casino';
    // }

    // if(props.match.params.provider_id === '213' || props.match.params.provider_id === '214') {
    //     window.location.href = `/live-casino/${selectedSlot.ProviderID}/${selectedSlot.GameCode}?isMobile=0`;
    //     return false;
    // }

    const UnderMaintenance = () => {
        return (
            <React.Fragment>
                <div className='container game-maintenance-header'>
                    <div className='row justify-content-center align-items-center'>
                        <div className="col-auto">
                            <div style={{padding: '20px'}}>
                                <a href="/">
                                    <img className="ImgFluid" src="/assets/img/15BET-Logo.png" alt="Logo" style={{height: '48px'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <section 
                    class="game-maintenance-section" 
                    style={{
                        width: '100%',
                        height: '100vh',
                        border: 0,
                        padding: 0,
                        margin: '-50px 0 0 0',
                    }}
                >
                    <div class="container-fluid h-100">
                        <div class="error-page">
                        <div class="row align-items-center justify-content-center full-height">
                            <div class="container">
                            <div class="box-container">
                                <div class="row align-items-center">
                                <div class="col-lg-auto mr-lg-2">
                                    <img class="warning" src="/assets/img/icons/maintain.svg" />
                                </div>
                                <div class="col-lg">
                                    <div class="content">
                                        <p class="title">{errors.errors.content && errors.errors.content.length !== 0 ? errors.errors.content[lang].Title: t('game maintenance title')}</p>
                                    </div>
                                    <div class="browser-section mt-lg-4 mt-md-3 p-4">
                                        <p class="content-text mb-3">
                                        {ReactHtmlParser(errors.errors.content && errors.errors.content.length !== 0  ? errors.errors.content[lang].Message : t('game maintenance text'))}
                                        </p>
                                        <div class="row align-items-center justify-content-center justify-content-lg-start">
                                            <div class="col-auto pl-lg-0">
                                            <div class="row align-items-center justify-content-center justify-content-lg-start browser">
                                                <div class="col-auto icon p-0">
                                                        <img src="/assets/img/w365/icons/contact-us/cs-email.svg"/>
                                                </div>
                                                    <div class="col p-0">
                                                        <p>{t('cs support email inqury')}</p>
                                                        <p className="value">{t('cs support email')}</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="col-auto">
                                            <div class="row align-items-center justify-content-center justify-content-lg-start browser">
                                                <div class="col-auto icon p-0">
                                                        <img src="/assets/img/w365/icons/contact-us/telegram.svg"/>
                                                </div>
                                                    <div class="col-auto p-0">
                                                        <p>{t('telegram')}</p>
                                                        <p className="value">{t('cs-telegram')}</p>
                                                    </div>
                                                    <div className="d-flex tg-qr-icon-maintenance">
                                                        <img src="/assets/img/w365/icons/contact-us/tg_qr.png" alt="" />
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

    useEffect(() => {
        if(pathName.includes('gameLobby')) {
            if(selectedProvider.playerID === undefined) {
                window.location.href = '/live-casino';
            } else {
                postPlay(selectedProvider)
                .then(response => setUrl(response.data.url))
                .catch(error => {
                    setErrors(error.response.data);
                    if(error.response.data.errors && error.response.data.errors.player_blacklisted !== undefined) {
                        setBlacklistedError(true);
                    }
                });
            }
        } else {
            selectedGame = casinoGames.filter(game => game.GameTypeCode === props.match.params.game_type_code)[0];
            if (selectedGame){
                postPlay({
                    currency: 'KRW',
                    gameType: selectedGame.GameTypeCode,
                    gameID: selectedGame.GameTypeCode,
                    integrationID: selectedGame.IntegrationID,
                    hashid: "",
                    lang: localStorage.getItem('i18nextLng') === null ? 'en' : localStorage.getItem('i18nextLng').split('-')[0],
                    launchType: 'html5',
                    playerID: player.player.PlayerID,
                    product: 2,
                    provider: provider ? provider : 15,
                    username: player.username,
                    domain: window.location.hostname
                })
                    .then(response => setUrl(response.data.url))
                    .catch(error => {
                        setErrors(error.response.data);
                        if(error.response.data.errors && error.response.data.errors.player_blacklisted !== undefined) {
                            setBlacklistedError(true);
                        }
                    });
            }else if(casinoGames && selectedGame === undefined){ //if cant find selected game game code
                window.location.href = '/live-casino';
            }
        }
    }, [casinoGames])

    return (
        <div className={`m-0${blacklistedError ? ' blacklisted' : ''}`}>
            { 
                blacklistedError ? 
                <BlackListedError /> 
                :
                errors ? 
                <UnderMaintenance/>
                : 
                <iframe className="fit-frame" src={url}></iframe>
            }
        </div>
    );
}

export default Play;
