import React from 'react';
import { Row, Col } from 'reactstrap';

const Title = ({ notFiltered, selectedFilters, t}) => {
    return (
        <Row>
            <Col md="6" className="pl-2">
                <h4 className="form-title" style={{fontSize:'20px',color:'#FFFFFF'}}>
                {
                    selectedFilters.name.length == 0 ?
                    notFiltered ?
                    t('all slots games')
                    : selectedFilters.provider !== 'all providers' && selectedFilters.category !== 'all categories' ? t('all ' + selectedFilters.provider.toLowerCase() + ' games')
                    : selectedFilters.provider === 'all providers' ? t('all slots games') : t('all ' +selectedFilters.provider.toLowerCase()+ ' games')
                    : null
                }
                {
                    selectedFilters.name.length == 0 ? selectedFilters.provider !== 'all providers' && (selectedFilters.category !== 'all categories') ? ' > ' : '' : null
                }
                {
                    selectedFilters.name.length == 0 ? selectedFilters.provider === 'all providers' && (selectedFilters.category !== 'all categories') ? ' > ' : '' : null
                }
                {
                    selectedFilters.name.length == 0 ? selectedFilters.provider === 'all providers' && selectedFilters.category !== 'all categories' ?  t(selectedFilters.category.replace(' Game', '').toLowerCase() +' games') : '' : null
                }
                {
                    selectedFilters.name.length == 0 ? selectedFilters.provider !== 'all providers' && selectedFilters.category !== 'all categories' ?  t(selectedFilters.category.replace(' Game', '').toLowerCase() +' games') : '' : null
                } 
                {
                    selectedFilters.name.length > 0 ? t('search results') : null
                }
                </h4>
            </Col>
            <Col md="6"></Col>
        </Row>
    );
}

export default Title;
