import { combineReducers } from "redux";
import authReducer from "./authentication";
import banners from "./banner";
import registerReducer from "./registration";
import playerReducer from "./player";
import paymentReducer from "./payment";
import transactionHistoryReducer from "./transaction";
import promotionReducer from "./promotion";
import casinoReducer from "./casino";
import miniReducer from "./mini";
import sportsReducer from "./sports";
import pageReducer from "./page";
import bonusReducer from "./bonus";
import jackpotReducer from "./jackpot";
import slotsReducer from "./slot";
import modalReducer from "./modal";
import productReducer from "./product";
import partnerReducer from "./partner";
import ipReducer from "./ipaddress";
import historyReducer from "./history";
import bankReducer from "./bank";
import providerReducer from "./provider";
import errorReducer from "./error";
import sessionReducer from "./session";
import cashReducer from "./cash";
import virtualReducer from "./virtual";
import p2pReducer from "./p2p";
import arcadeReducer from "./arcade";
import errorTransferToastReducer from "./error-transfer-toast";
import rebateReducer from "./rebate";
import landingPageReducer from "./landingpage";
import minigameReducer from "./minigame";
import betConstructReducer from "./betconstruct";

export default combineReducers({
    auth: authReducer,
    banners: banners,
    promotions: promotionReducer,
    register: registerReducer,
    player: playerReducer,
    payment: paymentReducer,
    transactionHistory: transactionHistoryReducer,
    casino: casinoReducer,
    mini: miniReducer,
    sports: sportsReducer,
    page: pageReducer,
    bonuses: bonusReducer,
    jackpot: jackpotReducer,
    slots: slotsReducer,
    modal: modalReducer,
    products: productReducer,
    partner: partnerReducer,
    ip: ipReducer,
    historyes: historyReducer,
    banks: bankReducer,
    providers: providerReducer,
    errors: errorReducer,
    session: sessionReducer,
    cashs: cashReducer,
    virtual: virtualReducer,
    errorTransferToast: errorTransferToastReducer,
    p2p: p2pReducer,
    arcade: arcadeReducer,
    rebate: rebateReducer,
    landingpage: landingPageReducer,
    minigame: minigameReducer,
    betconstruct: betConstructReducer
});
