import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from 'redux-react-hook';
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton';

import {getUnreadMessage, getUnreadNotice } from "../../actions/authentication";

const Nav = ({ text, path, className, wallet, unReadMessageCount, unReadNoticeCount }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex align-items-center justify-content-center nav">
            <Link to={`/myaccount/${path}`} className={classNames(className, {active: window.location.pathname.includes(`/myaccount/${path}`)})}>
                {t(text.toLowerCase())}
                { path === "bonus" && wallet.promotion > 0 ?  <div className="badge badge-danger">{wallet.promotion}</div> : ""}
                { path === "messages" && unReadMessageCount > 0 ? <div className="badge badge-danger">{unReadMessageCount}</div> : ""}
                { path === "notices" && unReadNoticeCount >= 10 ? <div className="badge badge-danger">{"9+"}</div> : path === "notices" && unReadNoticeCount > 0 ? <div className="badge badge-danger">{unReadNoticeCount}</div> : ""}
            </Link>
        </div>
    );
}

const Sidebar = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { wallet } = useSelector(state => state.auth );
    const vip = wallet.vip || "";
    let location = useLocation();
    // const unReadMessage = useSelector(state => state.auth.unreadMessage.unread_message_replies_count);
    // const unReadNotice = useSelector(state => state.auth.unreadNotice.unread_notice_count);

    const auth = useSelector(state => state.auth);
    const { unreadMessage, unreadNotice } = auth;

    const unReadMessageCount = unreadMessage && unreadMessage.unread_message_replies_count !== undefined ? unreadMessage.unread_message_replies_count : 0
    const unReadNoticeCount = unreadNotice && unreadNotice.unread_notice_count !== undefined ? unreadNotice.unread_notice_count : 0


    useEffect(() => {
        dispatch(getUnreadMessage());
        dispatch(getUnreadNotice());
    }, []);


    const routes = [
        { path: "deposit", name: "Deposit", classname: "deposit" },
        { path: "withdrawal", name: "Withdrawal", classname: "withdrawal" },
        { path: "messages", name: "1 on 1 message", classname: "messages" },
        //{ path: "transfer", name: "Transfer", classname: "transfer" },
        //{ path: "rebate", name: "Rebate", classname: "rebate" },
        { path: "history", name: "Transaction History", classname: "transaction-history" },
        { path: "bonus", name: "bonus / coupon / cash", classname: "bcr" },
        { path: "profile", name: "Profile", classname: "profile" },
        { path: "notices", name: "Notices", classname: "notices" }
    ];

    const routeComponents = routes.map(({ path, name, classname }) => (
        <li>
            <Link  
                to={`/myaccount/${path}`}
                className={classNames(classname, {
                    active: location.pathname === `/myaccount/${path}` || (path === "messages" && location.pathname.includes("messages")) || (path === "notices" && location.pathname.includes("notices"))
                })}
                key={name}
            >
                {t(name.toLowerCase())}
                { path === "bonus" && wallet.promotion > 0 ?  <div class="badge badge-danger">{wallet.promotion}</div> : ""}
                { path === "messages" && unReadMessageCount > 0 ? <div class="badge badge-danger">{unReadMessageCount}</div> : ""}
                { path === "notices" && unReadNoticeCount > 0 ? <div class="badge badge-danger">{unReadNoticeCount}</div> : ""}
            </Link>
        </li>
    ));

    return (
        <div className="d-flex justify-content-center my-account-nav">
            {routes.map(route => <Nav text={route.name} className={route.classname} path={route.path} key={route.path} wallet={wallet} unReadMessageCount={unReadMessageCount} unReadNoticeCount={unReadNoticeCount}/>)}
        </div>
    );
};
export default Sidebar;
