import { PAGE_SUCCESS, PAGES_SUCCESS, PARTNER_PAGES_SUCCESS,SET_TEMPLATE_CONTENT, RESET_TEMPLATE_CONTENT, SET_BRAND_GAME_SETUP } from "../actions/types";

const initialState = {
  pages: {},
  partnerPages: {},
  page: {Content:''},
  content: [],
  brandGameSetup: [],
};



export default function(state = initialState, action) {
  switch (action.type) {
    case PAGES_SUCCESS:
      return {
          ...state,
          pages: action.payload.reduce(
              (accumulator, obj) => ({
                  ...accumulator,
                  [obj.Name]: obj.Pages
              }),
            {}),
      };
    case PAGE_SUCCESS:
      return {
          ...state,
          page: action.payload
      }
    case PARTNER_PAGES_SUCCESS:
      return {
          ...state,
          partnerPages: action.payload.reduce(
              (accumulator, obj) => ({
                  ...accumulator,
                  [obj.Name]: obj.Pages
              }),
            {}),
      };
      case SET_TEMPLATE_CONTENT:
         return {
          ...state,
          content: action.payload
      };
      case RESET_TEMPLATE_CONTENT:
        return {
          ...state,
          content: []
        };
      case SET_BRAND_GAME_SETUP:
        return {
          ...state,
          brandGameSetup: action.payload
        };
    default:
      return state;
  }
}
