import { GET_PAGE, GET_PAGES, GET_PARTNER_PAGES, GET_TEMPLATE_CONTENT, RESET_TEMPLATE_CONTENT, GET_BRAND_GAME_SETUP, SET_BRAND_GAME_SETUP  } from "./types";

export const getPages = () => {
    return {
        type: GET_PAGES
    };
};

export const getPage = (payload) => {
    return {
        type: GET_PAGE,
        payload
    };
};

export const getParnetPages = () => {
    return {
        type: GET_PARTNER_PAGES
    };
};

export const getTemplateContent = name => {
    return {
        type: GET_TEMPLATE_CONTENT,
        payload: name
    };
};

export const resetTemplateContent = () => {
  return {
    type: RESET_TEMPLATE_CONTENT
  };
};

export const getBrandGameSetup = (payload) => {
    return {
      type: GET_BRAND_GAME_SETUP,
      payload
    };
  };

  export const setBrandGameSetup = (payload) => {
    return {
      type: SET_BRAND_GAME_SETUP,
      payload
    };
  };